import { isStage, Stage } from "./types";

export const getStage = (): Stage => {
  const stage = "preprod-za";

  return isStage(stage) ? stage : "gamma-na";
};

export const getRumConfig = () => {
  let appID = "04ed73f5-b3b6-4db1-81c4-2269f33f6aca";
  let region = "eu-central-1";

  if (!appID) {
    // Missing Katal patch, must be dev buid so use whatever
    appID = "decf4c32-74fd-44d4-b835-2d2818a40812";
  }

  if (!region) {
    // Missing Katal patch, must be dev buid so use whatever
    region = "us-east-1";
  }

  return {
    appID,
    region,
    appVersion: "1",
  };
};
